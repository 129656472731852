import { ReactNode, useContext, useEffect, useState } from 'react';
import { Order } from 'types';
import { OrderContext } from './order.context';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { GetOrderDataUseCase } from 'use-cases/orders/get-order-data.use-case';
import { useNavigate } from 'react-router-dom';
import { GetPendingOrdersUseCase } from 'use-cases/orders/get-pending-orders.use-case';

interface OrderProviderProps {
  children: ReactNode;
}

export function OrderProvider({ children }: OrderProviderProps) {
  const [order, setOrder] = useState<Order | null>(null);
  const navigate = useNavigate();
  const { leadData } = useContext(LeadDataContext);

  const canceledStatus = [
    'user_cancelled',
    'admin_cancelled',
    'driver_canceled',
    'user_cancelled',
    'admin_cancelled',
    'driver_cancelled',
    'manager_cancelled',
    'accept_expired'
  ];

  async function getOrderData(orderId: string) {
    await new GetOrderDataUseCase()
      .handle(orderId)
      .then((data) => {
        // if (data && data.canceled_at && canceledStatus.includes(data?.status)) {
        //   const today = new Date();
        //   const HOURS_DIFFERENCE_TO_CANCEL_RATING = 24;
        //   const cancelledAt = data.canceled_at.split(' ')[0].split('/');
        //   const cancelledDate = cancelledAt && new Date(cancelledAt.toString());

        //   const differenceTodayAndCancelled = differenceInHours(
        //     cancelledDate,
        //     today
        //   );

        //   const isExpired =
        //     differenceTodayAndCancelled >= HOURS_DIFFERENCE_TO_CANCEL_RATING;

        //   if (!isExpired) {
        //     setOrder(data);
        //     navigate('/order-cancelled');
        //   }
        // }

        if (
          (data && canceledStatus.includes(data?.status)) ||
          data?.rated ||
          data?.status == 'expired'
        ) {
          setOrder(null);
        } else if (data?.id) {
          setOrder(data);
          navigate('/order-tracking');
        }
      })
      .catch((e) => {
        console.log('getOrderErr', e);
      });
  }

  async function getPendingOrders() {
    await new GetPendingOrdersUseCase()
      .handle()
      .then((data) => {
        if (data[0]) {
          setOrder(data[0]);
        }
      })
      .catch((e) => {
        console.log('getPendingOrdersErr', e);
      });
  }

  useEffect(() => {
    leadData?.last_order_id && getOrderData(leadData.last_order_id);
    leadData?.token && getPendingOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadData]);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
}
