import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Order } from 'types';

export class GetPendingOrdersUseCase {
  private api = appGasService;

  async handle() {
    const { data } = await this.api.get<unknown, AxiosResponse<Order[]>>(
      `/v3/integrations/orders/getPendingOrders`
    );

    return data;
  }
}
